.admin-index {
    table {
        td {
            vertical-align: middle;
            img {
                max-height: 50px;
                width: auto;
            }
        }
    }
}
.admin-edit-locations {
    img.map {
        width: 100%;
        height: auto;
    }
    .map-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        display: grid;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        @for $i from 1 through 100 {
            &.columns-#{$i} {
                grid-template-columns: repeat(#{$i}, 1fr);
            }
            &.rows-#{$i} {
                grid-template-rows: repeat(#{$i}, 1fr);
            }
        }

        .cell {
            &:nth-child(2n+1) {
                background-color: rgba($primary, 0.15);
            }
            &.active {
                background-color: rgba($warning, 0.65);
            }

        }
    }
}