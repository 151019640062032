// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~jquery-ui/dist/themes/black-tie/jquery-ui.min';

@import 'quiz';
@import 'admin';
@import 'home';



#app {
    min-height: 100vh;
}

html, body {

    background-image: url('/images/scarlet_violet_bg.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}