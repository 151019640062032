
body {
    // background-image: url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='60' height='30' patternTransform='scale(2) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0,0%,100%,1)'/><path d='M1-6.5v13h28v-13H1zm15 15v13h28v-13H16zm-15 15v13h28v-13H1z'  stroke-width='1' stroke='none' fill='hsla(258.5,59.4%,59.4%,1)'/><path d='M31-6.5v13h28v-13H31zm-45 15v13h28v-13h-28zm60 0v13h28v-13H46zm-15 15v13h28v-13H31z'  stroke-width='1' stroke='none' fill='hsla(339.6,82.2%,51.6%,1)'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>")
    background-image: url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='60' height='30' patternTransform='scale(16) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0, 0%, 0%, 1)'/><path d='M1-6.5v13h28v-13H1zm15 15v13h28v-13H16zm-15 15v13h28v-13H1z'  stroke-width='1' stroke='none' fill='hsla(258.5,59.4%,59.4%,1)'/><path d='M31-6.5v13h28v-13H31zm-45 15v13h28v-13h-28zm60 0v13h28v-13H46zm-15 15v13h28v-13H31z'  stroke-width='1' stroke='none' fill='hsla(339.6,82.2%,51.6%,1)'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>")

}
.home-index {
    img.map {
        width: 100%;
        height: auto;
    }
    .map-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        display: grid;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        @for $i from 1 through 100 {
            &.columns-#{$i} {
                grid-template-columns: repeat(#{$i}, 1fr);
            }
            &.rows-#{$i} {
                grid-template-rows: repeat(#{$i}, 1fr);
            }
        }

        .cell {
            opacity: 0.65;
            // &:nth-child(2n+1) {
            //     background-color: rgba($primary, 0.15);
            // }
            &.active {
                background-color: rgba($warning, 0.65);
            }
            position: relative;
            z-index: 0;
            .pokemon-finder {
                position: absolute;
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%);
                clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
                overflow: hidden;
                pointer-events: none;
                background-color: rgba(0,0,0,0.5);
                z-index: 100;
                color: white;
                padding: 5px 15px;

            }   
            &:hover, &.active{
                .pokemon-finder {
                    clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
                }
                opacity: 0.8;
                border: 1px solid #000;
            }

        }
    }

    .type-select-wrapper {
        // width: 200px;
        position: relative;
        .selected-type{
            background-color: rgba(0,0,0,0.5);
            border: 1px solid rgba(0,0,0,1);
            padding: 10px 15px;
            border-radius: 4px;
            .none {
                color: white;
            }
        }
        .type-wrapper {
            text-decoration: none;
            display: flex;
            background-color: rgba(0,0,0,0.5);
            border: 1px solid rgba(0,0,0,1);
            padding: 10px 15px;
            border-radius: 4px;
        }
        .type {
            color: white;
            border-radius: 100px;
            padding: 5px 15px;
            width: 100%;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
        }
        .dropdown {
            width: 100%;
            max-height: 0px;
            transition: all 200ms ease-in-out;
            position: absolute;
            bottom: 100%;
            left: 0px;
            // clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);

            overflow: hidden;
        }

        &.active {
            .dropdown {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                max-height: 300px;
                overflow: auto;
            }
        }
    }

    .pokemon-select-wrapper {
        // width: 400px;
        position: relative;
        .selected-pokemon{
            background-color: rgba(0,0,0,0.5);
            border: 1px solid rgba(0,0,0,1);
            padding: 10px 15px;
            border-radius: 4px;
            .none {
                color: white;
            }
        }
        .pokemon-wrapper {
            text-decoration: none;
            display: flex;
            background-color: rgba(0,0,0,0.5);
            border: 1px solid rgba(0,0,0,1);
            padding: 10px 15px;
            border-radius: 4px;
        }
        .pokemon {
            color: white;
            border-radius: 100px;
            padding: 5px 15px;
            width: 100%;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            img {
                max-height: 50px;
            }
            .types {

                padding: 0px 10px;
                span {
                    border-radius: 100px;
                    padding: 2px 10px;
                    margin: 3px 0px;
                    font-size: 10px;
                }
            }
        }
        .dropdown {
            width: 100%;
            max-height: 0px;
            transition: all 200ms ease-in-out;
            position: absolute;
            bottom: 100%;
            left: 0px;
            // clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);

            overflow: hidden;
        }

        &.active {
            .dropdown {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                max-height: 300px;
                overflow: auto;
            }
        }
    }

    .possible-pokemon-table {
        img {
            max-height: 50px;
            width: auto;
        }
        .types {

            padding: 5px 10px;
            span {
                border-radius: 100px;
                padding: 2px 10px;
                margin: 3px 0px;
                font-size: 10px;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }
    .possible-col {
        max-height: 600px;
        overflow: auto;
    }
}